import {useState, Suspense} from "react";
import {Link, Outlet} from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Preloader from "../Preloader/Preloader";

const Layout = () => {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const handleBurgerClick = () => {
        setIsBurgerOpen(true);
    };

    const closeBurger = () => {
        setIsBurgerOpen(false);
    };
    return (
        <>
            <Header
                onClose={closeBurger}
                onOpen={handleBurgerClick}
                isOpenBurgerMenu={isBurgerOpen}
            />
            <div className="page-content">
                <Suspense fallback={<Preloader/>}>
                    <Outlet/>
                </Suspense>
            </div>
            <Footer/>
        </>
    )
}

export {Layout};