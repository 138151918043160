import React, {useState, useRef} from 'react';
import {Link as RouterLink, Route, Routes, useNavigate, useParams, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {Element, scroller} from 'react-scroll';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import EducationLevelSelection from "../EducationLevelSelection/EducationLevelSelection";
import {educationLevels} from '../EducationLevelSelection/EducationLevelSelection';
import './DocumentLinksPage.css';

const DocumentLinksPage = () => {
    const navigate = useNavigate();
    const {level} = useParams();
    const location = useLocation();
    const [containerHeight, setContainerHeight] = useState('auto');
    const containerRef = useRef(null);

    const validLevels = educationLevels.map(l => l.key);
    if (!level || !validLevels.includes(level)) {
        return <EducationLevelSelection/>;
    }

    const appsPath = `./${level}`;

    const App1Component = require(`./App1`).default;

    const handleScrollLinkClick = (path) => {
        navigate(`/documents/${level}/${path}`);
    };

    const contentContainer = document.querySelector('.DocumentLinksPage__content-container');

    if (contentContainer) {
        scroller.scrollTo('DocumentLinksPage__information-container', {
            duration: 500,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -120,
        });
    }

    const selectedLevel = educationLevels.find(l => l.key === level);
    const selectedLevelId = selectedLevel._id;


    const selectedLevelsId = (selectedLevelId === '1' || selectedLevelId === '2')
        ? [1, 2]
        : [Number(selectedLevelId)];

    const handleBachelorLinkClick = (event) => {
        event.preventDefault();

        localStorage.setItem('selectedLevelsId', JSON.stringify(selectedLevelsId));
        window.open('/main/?scrollTo=bachelor', '_blank');
    };

    return (
        <div className="DocumentLinksPage__container">
            <ul className="DocumentLinksPage__list">
                <li>
                    <a href="https://asu.art-gzhel.ru/default.aspx?WCI=ENTRANCESTATFORALL&WCE=GOTO.PAGES."
                       target="_blank" rel="noopener noreferrer">
                        Информация о количестве поданных заявлений <FontAwesomeIcon icon={faExternalLinkAlt}
                                                                                    className="external-link-icon"/>
                    </a>
                </li>
                <li>
                    <a href="https://asu.art-gzhel.ru/default.aspx?WCI=ENTRANTLIST&WCE=GOTO.PAGES."
                       target="_blank" rel="noopener noreferrer">
                        Список лиц, подавших документы, необходимых для поступления <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="external-link-icon"/>
                    </a>
                </li>
                <li>
                    <a href="https://asu.art-gzhel.ru/default.aspx?WCI=RECOMMENDEDLIST&WCE=GOTO.PAGES."
                       target="_blank" rel="noopener noreferrer">
                        Ранжированные списки <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="external-link-icon"/>
                    </a>
                </li>
                <li>
                    <RouterLink to={`app1`} onClick={() => handleScrollLinkClick(`app1`)}>
                        Списки зачисленных
                    </RouterLink>
                </li>
            </ul>
            <Element name="DocumentLinksPage__information-container"
                     className="DocumentLinksPage__information-container">
                <div style={{transition: 'height 500ms ease', height: containerHeight}} ref={containerRef}>
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={500}
                            onEntering={() => {
                                if (containerRef.current) {
                                    setContainerHeight(containerRef.current.clientHeight);
                                }
                            }}
                            onEntered={() => setContainerHeight('auto')}
                        >
                            <Routes location={location}>
                                <Route path={`app1`} element={<App1Component/>}/>
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </Element>
        </div>
    );
};

export default DocumentLinksPage;