import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './AdvantagesSlider.css';
import { Navigation, Autoplay } from 'swiper/modules';
import {
    faChevronLeft,
    faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdvantagesSlider = ({items}) => {

    return (
        <div className="advantages__slider-container">
            <div className="custom-arrows">
                <button className="arrow custom-prev">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button className="arrow custom-next">
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
            <Swiper
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                navigation={{
                    prevEl: '.custom-prev',
                    nextEl: '.custom-next',
                }}
                breakpoints={{
                    480: {
                        slidesPerView: 1,
                    },
                    668: {
                        slidesPerView: 2,
                    },
                    1025: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 4,
                    },
                    1600: {
                        slidesPerView: 5,
                    }
                }}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="custom-swiper"
            >
                {items.map((advantage) => (
                    <SwiperSlide key={advantage.id}>
                        <div className="advantages__card">
                            <FontAwesomeIcon
                                icon={advantage.icon}
                                className="advantages__card-icon"
                            />
                            <h3 className="advantages__card-title">{advantage.title}</h3>
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
};

export default AdvantagesSlider;