import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import {
    faMapMarkedAlt,
    faShieldAlt,
    faCouch,
    faCity,
    faBuilding,
    faWalking,
    faLeaf,
    faWifi,
    faVideo,
    faSmileBeam,
    faLock,
    faBed,
    faMoneyBillAlt,
    faCreditCard, faCalendarAlt, faMapMarkerAlt, faPhoneAlt
} from '@fortawesome/free-solid-svg-icons';
import ResponsiveSlider from "../AdvantagesSlider/AdvantagesSlider";
import CardContainer from "../CardContainer/CardContainer";
import ThumbsSlider from "../ThumbsSlider/ThumbsSlider";


const App5 = () => {

    return (
        <div className="DocumentLinksPage__content-container">
            <h4>Бакалавриат</h4>
            <p>
                <h5>Списки зачисленных на основные места в рамках КЦП</h5>
                <ul>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_956.pdf" target={"_blank"}>
                        Сведения о зачислении от 07.08.2024 № 956 (Очно. Бюджет)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_957.pdf" target={"_blank"}>
                        Сведения о зачислении от 07.08.2024 № 957 (Заочно. Бюджет)
                    </a></li>
                </ul>
                <h5>Списки зачисленных на места в пределах квот:</h5>
                <ul>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_906.pdf" target={"_blank"}>
                        Сведения о зачислении от 29.07.2024 № 906 (Правительственная квота)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_908.pdf" target={"_blank"}>
                    Сведения о зачислении от 29.07.2024 № 908 (Особая квота. Очно)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_910.pdf" target={"_blank"}>
                        Сведения о зачислении от 29.07.2024 № 910 (Особая квота. Заочно)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_912.pdf" target={"_blank"}>
                        Сведения о зачислении от 29.07.2024 № 912 (Отдельная квота. Очно)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_913.pdf" target={"_blank"}>
                        Сведения о зачислении от 29.07.2024 № 913 (Отдельная квота. Заочно)
                    </a></li>
                </ul>
            </p>
            <br/>
            <h4>Специалитет</h4>
            <p>
                <h5>Списки зачисленных на основные места в рамках КЦП</h5>
                <ul>
                    <li><a href="https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_955.pdf" target={"_blank"}>
                        Сведения о зачислении от 07.08.2024 № 955 (Очно. Бюджет)
                    </a></li>
                </ul>
                <h5>Списки зачисленных на места в пределах квот:</h5>
                <ul>
                    <li><a href="https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_907.pdf" target={"_blank"}>
                    Сведения о зачислении от 29.07.2024 № 907 (Правительственная квота)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_909.pdf" target={"_blank"}>
                        Сведения о зачислении от 29.07.2024 № 909 (Особая квота. Очно)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_911.pdf" target={"_blank"}>
                        Сведения о зачислении от 29.07.2024 № 911 (Отдельная квота. Очно)
                    </a></li>
                </ul>
            </p>
            <br/>
            <h4>СРЕДНЕЕ ПРОФЕССИОНАЛЬНОЕ ОБРАЗОВАНИЕ (КОЛЛЕДЖ)</h4>
            <p>
                <h5>Приказы о зачислении:</h5>
                <ul>
                    <li><a href="https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_917.pdf" target={"_blank"}>
                        Приказ о зачислении от 31.07.2024 № 917-ЛСО
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_934.pdf" target={"_blank"}>
                        Приказ о зачислении от 02.08.2024 № 934-ЛСО (внебюджет, очно, 9 кл.)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_935.pdf" target={"_blank"}>
                        Приказ о зачислении от 02.08.2024 № 935-ЛСО (внебюджет, очно, 11 кл.)
                    </a></li>
                    <li><a href="https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_936.pdf" target={"_blank"}>
                        Приказ о зачислении от 02.08.2024 № 936-ЛСО (внебюджет, заочно)                    </a></li>
                </ul>
            </p>
        </div>
    );
}

export default App5;
