const request = (url, options) => {
    return fetch(url, options).then(checkResponse);
}

const checkResponse = (res) => {
    return res.ok ? res.json() : Promise.reject(`Ошибка: ${res.status}`);
}

export const getPropertys = () => {
    const url = 'https://priem.art-gzhel.ru/api/propertys';
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return request(url, options);
};

export const getEduProgram = (propertyType) => {
    const url = 'https://priem.art-gzhel.ru/api/directions';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({propertyType})
    };
    return request(url, options);
};

export const getEge = () => {
    const url = 'https://priem.art-gzhel.ru/api/ege';
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    return request(url, options);
};

export const searchDir = (egeIDs,forms) => {
    const url = 'https://priem.art-gzhel.ru/api/directions/find-dir-by-ege';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({egeIDs,forms})
    };
    return request(url, options);
};

export const getEgeByID = (_id) => {
    const url = `https://priem.art-gzhel.ru/api/ege/${_id}`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    return request(url, options);
};

export const getExamByID = (_id) => {
    const url = `https://priem.art-gzhel.ru/api/exam/${_id}`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };
    return request(url, options);
};

export const sendEmail = (fio, phoneNumber, email, message, recaptchaToken) => {
    const url = 'https://priem.art-gzhel.ru/api/feedback';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fio, phoneNumber, email, message, recaptchaToken}),
    };

    return request(url, options);
};

export const getEduProgramByID = (id) => {
    const url = `https://priem.art-gzhel.ru/api/directions/find-dir-by-id`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({id})
    };
    return request(url, options);
};