import React, {lazy} from "react";
import "./App.css";
import {Layout} from "../Layout/Layout";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import {getPropertys, getEduProgram} from "../../api/Api";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import DocumentLinksPage from "../DocumentLinksPage/DocumentLinksPage";
import 'bootstrap/dist/css/bootstrap.min.css';

const MainPage = lazy(() => import ("../Main/Main"));

const DirectionsPage = lazy(() => import("../DirectionPage/DirectionPage"));

const CalendarPage = lazy(() => import("../Calendar/Calendar"));

const App = () => {
    const [propertys, setPropertys] = React.useState([]);
    const [eduProgram, setEduProgram] = React.useState([]);
    const [isBurgerOpen, setIsBurgerOpen] = React.useState(false);
    const location = useLocation();
    const isNotFoundPage = location.pathname === "/404";

    React.useEffect(() => {
        getPropertys()
            .then((res) => {
                setPropertys(res);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, []);

    React.useEffect(() => {
        getEduProgram([1, 2])
            .then((data) => {
                setEduProgram(data);
            })
            .catch((error) => {
                console.error("Ошибка при загрузке программ обучения:", error);
            });
    }, []);

    const handleBurgerClick = () => {
        setIsBurgerOpen(true);
    };

    const closeBurger = () => {
        setIsBurgerOpen(false);
    };

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route
                        path="main"
                        element={
                            <MainPage
                                propertys={propertys}
                                eduProgram={eduProgram}
                                setEduProgram={setEduProgram}
                            />
                        }
                    />
                    <Route index element={<Navigate to="/main" replace/>}/>
                    <Route path="calendar" element={<CalendarPage/>}/>
                    <Route path="program/:id" element={<DirectionsPage/>}/>
                    <Route path="documents/:level/*" element={<DocumentLinksPage />} />
                    <Route path="documents" element={<DocumentLinksPage />} />
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <ScrollToTop/>
        </div>
    );
};

export default App;
