import React from "react";
import {Link} from "react-router-dom";
import OKLOGO from "../../images/OK.svg";
import YOUTUBE from "../../images/Youtube.svg";
import VK from "../../images/vk.svg";
import TELEGRAM from "../../images/telegram.svg";
import "./Footer.css";
import DecorativeBorder from "../DecorativeBorder/DecorativeBorder";

const Footer = () => {
    return (
        <footer>
            <DecorativeBorder/>
            <div className="footer__container">
                <div className="footer__address-container">
          <span className="footer__organization-address">
            140155, Московская обл., Раменский городской округ, пос.
            Электроизолятор, д. 67
          </span>
                    <div>
                        <a href="https://yandex.ru/maps/?rtext=~55.588130, 38.495352" className="footer__route-link">
                            Построить маршрут
                        </a>
                    </div>
                </div>
                <div className="footer__organization-container">
                    <p className="footer__organization-text">
                        Федеральное государственное бюджетное образовательное учреждение
                        высшего образования «Гжельский государственный университет»
                    </p>
                </div>
                <div className="footer__link-container">
                    <nav className="footer__link-list">
                        <Link
                            className="footer__link"
                            to="https://t.me/ggu_gzhel"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={TELEGRAM} className="footer__icon" alt="Telegram"/>
                        </Link>
                        <Link
                            className="footer__link"
                            to="https://vk.com/artgzhel"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={VK} className="footer__icon" alt="Вконтакте"/>
                        </Link>
                        <Link
                            className="footer__link"
                            to="https://www.youtube.com/channel/UCd1_ANy1rcsJnaLkJ09RcAQ"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={YOUTUBE} className="footer__icon" alt="YouTube"/>
                        </Link>
                        <Link
                            className="footer__link"
                            to="https://ok.ru/group/70000004266670"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={OKLOGO} className="footer__icon" alt="Одноклассники"/>
                        </Link>
                    </nav>
                </div>
            </div>
        </footer>
    );
};

export default Footer;