import React from 'react';
import "./Header.css";
import LOGO from "../../images/header__logo.svg";
import Burger from "../Burger/Burger";
import { Link } from "react-router-dom";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

const Header = ({ onClose, onOpen, isOpenBurgerMenu }) => {
    const phoneNumber = '+78002014533';

    return (
        <header className="Header">
            <nav className="header__container">
                <Link to="/">
                    <img src={LOGO} className="header__logo" alt="Логотип ГГУ" />
                </Link>
                <nav className='header__navigation'>
                    <DropdownMenu />
                </nav>
                <div className="header__icon-container">
                    <button className="header__button header__button_gos"
                            onClick={() => window.open('https://www.gosuslugi.ru/vuzonline/search?ids=1065', '_blank')}/>
                    <button className="header__button header__button_calendar"
                            onClick={() => window.location.href = '/calendar'}/>
                    <button className="header__button header__button_call"
                            onClick={() => window.location.href = `tel:${phoneNumber}`}/>
                    <button className="header__button header__button_burger" onClick={onOpen}/>
                    {isOpenBurgerMenu && <Burger onClose={onClose}/>}
                </div>
            </nav>
        </header>
    );
}

export default Header;
