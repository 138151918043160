import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/autoplay';

import './ThumbsSlider.css';
import { FreeMode, Mousewheel, Navigation, Thumbs, Autoplay } from 'swiper/modules';
import Modal from "../Modal/Modal"; // Импортируем компонент Modal

const ThumbsSlider = ({ images, popupFullPhoto }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [popupImage, setPopupImage] = useState(null);

    const handleImageClick = (img) => {
        if (popupFullPhoto) {
            setPopupImage(img);
        }
    };

    const handleClosePopup = () => {
        setPopupImage(null);
    };

    return (
        <div className="thumbsSlider">
            <div className="swiper-slider-container">
                <Swiper
                    spaceBetween={10}
                    thumbs={{
                        swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                    }}
                    modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                    className='swiper-second'
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                >
                    {images.map((img, imgId) => (
                        <SwiperSlide key={imgId}>
                            <img
                                onClick={() => handleImageClick(img)}
                                className='swiper-second__img'
                                src={img}
                                alt='фото товара'
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={8}
                    slidesPerView={5}
                    freeMode={true}
                    mousewheel={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Mousewheel, Navigation, Thumbs]}
                    className='swiper-first'
                    direction='vertical'
                >
                    {images.map((img, imgId) => (
                        <SwiperSlide key={imgId}>
                            <img className='swiper-first__img' src={img} alt='фото товара' />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <Modal isOpen={!!popupImage} onClose={handleClosePopup} children={<img src={popupImage} alt="Full Size"
                                                                                   className="popup-image"/>}>
            </Modal>
        </div>
    );
};

export default ThumbsSlider;
