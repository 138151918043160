import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import './EducationLevelSelection.css';

export const educationLevels = [
    {key: 'bachelor', label: 'Бакалавриат', _id: "2"},
    {key: 'specialist', label: 'Специалитет', _id: '1'},
    {key: 'master', label: 'Магистратура', _id: '3'},
    {key: 'phd', label: 'Аспирантура', _id: '7'},
    {key: 'spo', label: 'Колледж', _id: '4'},
];

const EducationLevelSelection = () => {
    return (
        <div className="educationLevelSelection__container">
            <h1>Выберите уровень образования</h1>
            <ul className="educationLevelSelection__list">
                {educationLevels.map(level => (
                    <li key={level.key}>
                        <RouterLink to={`/documents/${level.key}`}>{level.label}</RouterLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EducationLevelSelection;