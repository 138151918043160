import React, {useEffect} from 'react';
import './Modal.css';


const Modal = ({ isOpen, onClose, children }) => {

    useEffect(() => {

        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal__overlay" onClick={onClose}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                {children}
                <button className="modal__close" onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
}

export default Modal;