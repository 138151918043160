import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {educationLevels} from '../EducationLevelSelection/EducationLevelSelection';
import './DropdownMenu.css';

const DropdownMenu = () => {
    const location = useLocation();
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleToggle = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const generateDropdownData = () => {
        return educationLevels.map(level => ({
            title: level.label,
            items: [
                // {label: 'Правила приема', href: `/documents/${level.key}/app1`},
                // {label: 'Необходимые документы', href: `/documents/${level.key}/app2`},
                // {label: 'Сроки проведения приема', href: '/calendar'},
                // {label: 'Направления подготовки / специальности', href: '/program'},
                // {label: 'Вступительные испытания', href: `/documents/${level.key}/app3`},
                // {label: 'Особые права (льготы)', href: `/documents/${level.key}/app4`},
                // {
                //     label: 'Сведения о ходе приема',
                //     href: `https://asu.art-gzhel.ru//default.aspx?WCI=ENTRANCESTATFORALL&WCE=GOTO.PAGES`
                // },
                // {label: 'Общежитие', href: `/documents/${level.key}/app5`},
                {
                    label: 'Сведения о ходе приема',
                    href: `/documents/${level.key}`
                }
            ],
        }));
    };

    const dropdownData = generateDropdownData();

    return (
        <div className='dropdownMenu__container'>
            {dropdownData.map((menu, index) => (
                <Dropdown
                    className='dropdownMenu__navigation__dropdown'
                    key={index}
                    show={openDropdown === index}
                    onToggle={() => handleToggle(index)}
                >
                    <Dropdown.Toggle className='dropdown-toggle-custom' id={`dropdown-basic-${index}`} variant="link">
                        {menu.title} <span className={`arrow-down ${openDropdown === index ? 'rotated' : ''}`}>▼</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {menu.items.map((item, idx) => (
                            <Dropdown.Item href={item.href} key={idx} target={"_blank"}>
                                {item.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            ))}
        </div>
    );
};

export default DropdownMenu;
